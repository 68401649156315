import { useRef } from "react";
import emailjs from "@emailjs/browser";
import Swal from "sweetalert2";
import { ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../css/contactus.css";
import "../../css/form.css";
import GoogleMaps from "../map.component";

const ContactUs = () => {
  const serviceID = "service_m78aeth";
  const templateID = "template_q5fcvsd";
  const publicKey = "MahrHKbDaznFGy-Io";

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm(serviceID, templateID, form.current, publicKey).then(
      (result) => {
        console.log(result.text);

        Swal.fire({
          title: "Success!",
          text: "Your message has been sent successfully!",
          icon: "success",
          buttonsStyling: false,
          showConfirmButton: false,
          timer: 3000,
          backdrop: `
    rgba(0,0,0,0.8)
    left top
    no-repeat
  `,
          background: "rgba(0, 0, 0, 0)",
          customClass: {
            popup: "custom-swal-popup",
            confirmButton: "custom-swal-confirm-button",
          },
        });
        form.current.reset();
      },
      (error) => {
        console.log(error.text);

        Swal.fire({
          title: "Error",
          text: "There was an error sending your message, please try again.",
          icon: "error",
          buttonsStyling: false,
          showConfirmButton: false,
          timer: 3000,
          backdrop: `
    rgba(0,0,0,0.8)
    left top
    no-repeat
  `,
          background: "rgba(0, 0, 0, 0)",
          customClass: {
            popup: "custom-swal-popup",
            confirmButton: "custom-swal-confirm-button",
          },
        });
      }
    );
  };

  return (
    <div className="contactusBox" data-aos="fade-up">
      <div className="contactusInfo">
        <div className="contactusItem">
          <i className="fa fa-envelope"></i>{" "}
          <span className="contactusLabel">Email</span>
          <p>admin@devClimbers.com</p>
        </div>
        <div className="contactusItem">
          <i className="fa fa-phone"></i>{" "}
          <span className="contactusLabel">Phone</span>
          <p>+52 (646) 101 5351</p>
        </div>
        <div className="contactusItem">
          <i className="fa fa-map-marker"></i>{" "}
          <span className="contactusLabel">Location</span>
          <p>Ensenada, Baja California, Mexico</p>
        </div>
        <div className="mapContainer">
          {/* <img src="/images/ensenadaSatelite.png" alt="Mapa de Ensenada" /> */}
          <GoogleMaps />
        </div>
      </div>

      <div className="mac-window-contact">
        <div className="title-bar">
          <div className="window-controls">
            <div className="control close"></div>
            <div className="control minimize"></div>
            <div className="control maximize"></div>
          </div>
          <span>Contact_Us</span>
        </div>
        <p className="contactus-write-animation">{">Contact Us"}</p>
        <div className="content">
          <form className="form" ref={form} onSubmit={sendEmail}>
            <div className="input-group mb-5  floating-label">
              <input
                name="from_name"
                type="text"
                className="form-control"
                id="firstName"
                placeholder=" "
                //                 // onChange={handleChange}
                required
              />
              <label htmlFor="firstName">First Name</label>
              {/* <label htmlFor="firstName">{"<First Name/>"}</label> */}
            </div>

            <div className="input-group mb-5  floating-label">
              <input
                name="from_lastname"
                type="text"
                className="form-control"
                id="lastName"
                placeholder=" "
                //                 // onChange={handleChange}
                required
              />
              <label htmlFor="lastName">Last Name</label>
              {/* <label htmlFor="lastName">{"<Last Name/>"}</label> */}
            </div>

            <div className="input-group mb-5  floating-label">
              <input
                name="from_email"
                type="email"
                className="form-control"
                id="email"
                placeholder=" "
                //                 // onChange={handleChange}
                required
              />
              <label htmlFor="email">Email</label>
              {/* <label htmlFor="email">{"<Email/>"}</label> */}
            </div>

            <div className="input-group mb-5  floating-label">
              <input
                name="from_phone"
                type="tel"
                className="form-control"
                id="phone"
                placeholder=" "
                //                 // onChange={handleChange}
                required
              />
              <label htmlFor="phone">Phone Number</label>
              {/* <label htmlFor="email">{"<Phone Number/>"}</label> */}
            </div>

            <div className="input-group mb-5  floating-label">
              <textarea
                name="from_message"
                className="form-control-message"
                id="message"
                rows="4"
                placeholder="Message"
                // placeholder={"<Message/>"}
                //                 // onChange={handleChange}
                required
              ></textarea>
              {/* <label htmlFor="message"></label> */}
            </div>

            <div className="card-footer">
              <button className="services-button">
                Send
              </button>
            </div>
          </form>
        </div>
      </div>
      <ToastContainer
        position="center"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable
        pauseOnHover={false}
        theme="colored"
      />
    </div>
  );
};

export default ContactUs;
