import { useRef, useEffect } from "react";
import {
  WebGLRenderer,
  Scene,
  OrthographicCamera,
  HemisphereLight,
  Group,
  MeshBasicMaterial,
} from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import './modelo.css'

function Modelo2() {
  const mountRef = useRef(null);
  const pivotRef = useRef(new Group());

  useEffect(() => {
    const scene = new Scene();
    const aspectRatio = window.innerWidth / window.innerHeight;
    const frustumSize = 10;
    const camera = new OrthographicCamera(
      (frustumSize * aspectRatio) / -2,
      (frustumSize * aspectRatio) / 2,
      frustumSize / 2,
      frustumSize / -2,
      1,
      1000
    );
    camera.position.set(-5, 5, 10);

    const renderer = new WebGLRenderer({ antialias: true, alpha: true });
    renderer.setSize(window.innerWidth-20, window.innerHeight);
    mountRef.current.appendChild(renderer.domElement);

    const Material1 = new MeshBasicMaterial({
      color: 0xffffff,
    });

    const Material2 = new MeshBasicMaterial({
      color: 0xffffff,
    });

    const loader = new GLTFLoader();
    loader.load("montanamasgrueso.glb", (gltf) => {
      gltf.scene.traverse((child) => {
        if (child.isMesh) {
          if (child.name === "montana_holdout") {
            child.material = Material2;
            child.material.colorWrite = false;
            child.renderOrder = 1;
          } else if (child.name === "montana_wireframe") {
            child.material = Material1;
            child.renderOrder = 2;
          }
        }
      });

      // Añadir el modelo al grupo de pivote
      pivotRef.current.add(gltf.scene);

      // Ajustar la posición del modelo dentro del grupo
      gltf.scene.position.set(-0.2, 0, -8.5); // Ajusta estos valores según sea necesario
      pivotRef.current.position.set(0, 0, -10);

      pivotRef.current.add(gltf.scene);
      scene.add(pivotRef.current);
    });

    const light = new HemisphereLight(0xffffbb, 0x080820, 1);
    scene.add(light);

    const container = mountRef.current;

    const animate = () => {
      requestAnimationFrame(animate);
      pivotRef.current.rotation.y += 0.0025;
      renderer.render(scene, camera);
    };
    animate();

    window.onresize = () => {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(window.innerWidth, window.innerHeight);
    };

    return () => {
      container.removeChild(renderer.domElement);
    };
  }, []);

  return <div ref={mountRef} className="neon-effect"></div>;
}

export default Modelo2;
