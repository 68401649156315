import "../../css/landing.css";
import "../../css/aboutus.css";
import "../../css/MacWindow.css"


const AboutUs = () => {
  return (
    <div className="aboutusBox">
      <div className="mac-window" data-aos="fade-up">
        <div className="title-bar">
          <div className="window-controls">
            <div className="control close"></div>
            <div className="control minimize"></div>
            <div className="control maximize"></div>
          </div>
          <span>About_Us</span>
        </div>
        <p className="aboutus-write-animation">{">Hello World!"}</p>
        <div className="content">
          <div className="aboutus-text">
            <p>
              Based in Ensenada, Mexico, DevClimbers is at the forefront of
              technological innovation. We specialize in process automation
              using cutting-edge technology to solve your business challenges.
              Our rapid growth, driven by our commitment to efficiency and
              client trust, is a testament to our dedication. Let us help
              transform your operations and elevate your business towards a more
              innovative future.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
   