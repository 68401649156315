import "../../css/Services.css";
import SofDev from "../../assets/images/SoftwareDevelopment.svg";
import SearchEngOpt from "../../assets/images/SearchEngineOpt.svg";
import UxDesg from "../../assets/images/UxDesign.svg";
import CloudServ from "../../assets/images/CloudServices.svg";
import DevOps from "../../assets/images/DevOps.svg";

const Services = () => {
  return (
    <div className="services-section">
      <h1 className="services-title">&lt; Services &gt;</h1>
      <div className="services-container">
        <div className="service">
          <img src={SofDev} alt="Software Development" />
          {/* <FaCode className="icon" /> */}
          <h3>Software Development</h3>
          <p>
            Crafting custom applications for web browsers, mobile devices, and
            desktop computers, tailored to meet specific business needs and user
            requirements.
          </p>
        </div>
        <div className="service">
          {/* <FaPaintBrush className="icon" /> */}
          <img src={UxDesg} alt="UI UX Design" />
          <h3>UI / UX Design</h3>
          <p>
            Designing the user interface (UI) and user experience (UX) for
            digital products, focusing on the aesthetics and usability to ensure
            a seamless and engaging interaction for users.
          </p>
        </div>
        <div className="service">
          {/* <FaSearch className="icon" /> */}
          <img src={SearchEngOpt} alt="Search Engine Optimization" />
          <h3>Search Engine Optimization</h3>
          <p>
            Enhancing the visibility of websites in search engine results,
            improving the chances of attracting more organic traffic by
            optimizing content and structure according to search engine
            algorithms.
          </p>
        </div>
        <div className="service">
          {/* <FaCloud className="icon" /> */}
          <img src={CloudServ} alt="Cloud Services" />
          <h3>Cloud Services</h3>
          <p>
            Providing remote computing services such as data storage, servers,
            databases, networking, and software through the cloud, offering
            scalability, flexibility, and cost-efficiency.
          </p>
        </div>
        <div className="service">
          {/* <FaCog className="icon" /> */}
          <img src={DevOps} alt="DevOps" />
          <h3>DevOps</h3>
          <p>
            Integrating and automating the processes between software
            development and IT teams, improving collaboration, and streamlining
            the lifecycle of systems development for faster and more efficient
            delivery of applications.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Services;
