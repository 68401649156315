import { Link } from "react-router-dom";
import SignedOutLinks from "./signed_out_links";
import "../../css/navbar.css";
import devLogo from "../../assets/images/icon_text_logo.svg";
import AlertDismisable from "../alerts/custom_alerts";


const Navbar = () => {
  return (
    <nav
      className="navbar navbar-expand-lg navbar-dark fixed-top"
      id="navbarNav"
    >
      <div className="container-fluid">
        {/* Logo a la izquierda */}
        <Link to="/#home" className="navbar-brand">
          <img src={devLogo} alt="Dev Climbers Logo" className="navbar-logo" />
        </Link>

        {/* Botón de hamburguesa */}
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavCollapse"
          aria-controls="navbarNavCollapse"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        {/* Contenido a la derecha */}
        <div
          className="collapse navbar-collapse navbar-collapse-right"
          id="navbarNavCollapse"
        >
          {/* <SignedInLinks /> */}
          <SignedOutLinks />
        </div>
      </div>
      <AlertDismisable />
    </nav>
  );
};

export default Navbar;
