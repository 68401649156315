import { Component } from "react";
import LandingPage from "./landing_page";
import AboutUs from "./about_us";
import ContacUs from "./contact_us";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { connect } from 'react-redux'
import Services from "./services";

class Dashboard extends Component {

  componentDidMount() {
    AOS.init({
      duration: 900, 
      delay: 100,
      once: false,
      easing: 'ease-in-out', 
    });
    
  }

  render() {
    return (
      <div className="p-0">
        <div className="column noOverflow">
          <section id="home" className="p-0">
            <LandingPage />
            {/* <HeaderSection/> */}
            {/* <Modelo2 /> */}
          </section>
          <section id="aboutus" className="p-0">
            <AboutUs />
          </section>
          <section id="services" className="p-0">
            <Services/>
          </section>
          <section id="contactus" className="p-0">
            <ContacUs />
          </section>
        </div>
        {/* <div className="row">
          <section id="home" className="p-0">
            <LandingPage/>
          </section>
          <section id="aboutus" className="p-0" >
            <AboutUs/>
          </section>
          <section id="contactus" className="p-0">
            <ContacUs/>
          </section>
        </div> */}
      </div>
    );
  }
}

const mapStateToProps = (state) =>{
  return {
    contacts: state.contact.contacts
  }
}

export default connect(mapStateToProps)(Dashboard);


{/* <section id="projects">
<Projects/>
</section> */}