import React, { useState } from "react";
import "../../css/form.css";
import "bootstrap-icons/font/bootstrap-icons.css";

const SignUp = () => {
  const [name, setName] = useState("");
  const [last, setLast] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleChange = (e) => {
    var id = e.target.id; 
    var value = e.target.value;
   if (id === 'firstName') {
    setName(value);
   }
   if (id === 'lastName') {
    setLast(value);
   }
   if (id === 'email') {
    setEmail(value);
   }
   if (id === 'password') {
    setPassword(value);
   }

  
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(name, last, email, password);
  };

  return (
    <div className="section section-signup py-5">
      <div className="container">
        <div className="row">
          <div className="card card-signup  p-4">
            <form className="form" onSubmit={handleSubmit}>
              <div className="card-header text-center pb-4">
                <h3 className="card-title title-up">Sign Up</h3>
              </div>
              <div className="social-buttons-container">
                <div className="social-line">
                  <a href="https://www.google.com/" className="btn btn-facebook btn-icon btn-lg btn-round btn-custom">
                    <i className="fab fa-facebook-square"></i>
                  </a>

                  <a
                    href="https://www.google.com/"
                    className="btn btn-twitter btn-icon btn-lg btn-round btn-custom"
                  >
                    <i className="fab fa-twitter"></i>
                  </a>
                  <a
                    href="https://www.google.com/"
                    className="btn btn-google btn-icon btn-lg btn-round btn-custom"
                  >
                    <i className="fab fa-google-plus"></i>
                  </a>
                </div>
              </div>
              <div className="card-body">
                <div className="input-group mb-4 floating-label">
                  <span className="input-group-text">
                    <i className="bi bi-pencil-fill" style={{ color: '#ffffff' }}></i>
                  </span>
                  <input
                    type="text"
                    className="form-control"
                    id="firstName"
                    placeholder=" "
                    onChange={handleChange}
                  />
                  <label htmlFor="firstName">{"<First_Name/>"}</label>
                </div>

                <div className="input-group mb-4 floating-label">
                  <span className="input-group-text">
                    <i className="bi bi-pencil-fill" style={{ color: '#ffffff' }}></i>
                  </span>
                  <input
                    type="text"
                    className="form-control"
                    id="lastName"
                    placeholder=" "
                    onChange={handleChange}
                  />
                  <label htmlFor="lastName">{"<Last_Name/>"}</label>
                </div>

                <div className="input-group mb-4 floating-label">
                  <span className="input-group-text">
                    <i className="bi bi-envelope-at-fill" style={{ color: '#ffffff' }}></i>
                  </span>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    placeholder=" "
                    onChange={handleChange}
                  />
                  <label htmlFor="email">{"<Email/>"}</label>
                </div>
                <div className="input-group mb-4 floating-label">
                  <span className="input-group-text">
                  <i className="bi bi-braces-asterisk" style={{ color: '#ffffff' }}></i>
                  </span>
                  <input
                    type="password"
                    className="form-control"
                    id="password"
                    placeholder=" "
                    onChange={handleChange}
                  />
                  <label htmlFor="password">{"<Password/>"}</label>
                </div>
				<div className="input-group mb-2 floating-label">
                  <span className="input-group-text">
                  <i className="bi bi-braces-asterisk" style={{ color: '#ffffff' }}></i>
                  </span>
                  <input
                    type="password"
                    className="form-control"
                    id="passwordR"
                    placeholder=" "
                    onChange={handleChange}
                  />
                  <label htmlFor="passwordR">{"<Repeat_password/>"}</label>
                </div>
              </div>
              <div className="card-footer">
                <button className="btn btn-neutral btn-round btn-lg">Get Started</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;

//
