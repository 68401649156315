import authReducer from "./auth_reducer";
import contactReducer from "./contact_reducer";
import { combineReducers } from "redux";

const rootReducer = combineReducers({
  auth: authReducer,
  contact: contactReducer,
});

export default rootReducer
