import "../../css/glitch.css";
import Modelo2 from "../animated/modelo2";
import '../../css/landing.css'

const LandingPage = () => {
  return (
    <>
      <div className="tabsContainer">
        <div class="hero-section">
          <h1>
            Elevating your ideas <br /> through technology.
          </h1>
          <h2>To The Top!</h2>
          <p>
            Welcome to DevClimbers, a leading software development firm
            specializing in mobile and web development. We are dedicated to
            crafting cutting-edge solutions that redefine business efficiency in
            the digital era. Explore how our innovative approach can
            revolutionize your online presence.
          </p>
          <a href="/#services" class="services-button-link">
            <button class="services-button">See Our Services</button>
          </a>
        </div>

        <Modelo2 />
      </div>
    </>
  );
};

export default LandingPage;
