import "../../css/footer.css";
import xLogo from "../../assets/images/facebook_logo.svg";
import tiktokLogo from "../../assets/images/tiktok_logo.svg";
import linkedinLogo from "../../assets/images/linkedin_logo.svg";
import instagramLogo from "../../assets/images/instagram_logo.svg";
import devLogo2 from "../../assets/images/logo.svg";
import decorL from "../../assets/images/DecorFooter.svg";
import decorR from "../../assets/images/DecorFooterR.svg";

const Footer = () => {
  return (
    <div className="footer2Container">
      <div className="footer2Row">
        {/* <hr className="footerhr" /> */}
        <img
          src={decorL}
          alt="decoracion Izquierda"
          className="footer-imagesL"
        />
        <div className="">
          <div className="footer2Row">
            <div className="footer2Row">
              <a
                href="https://www.instagram.com/devclimbers/"
                className="footer2-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="footer2Icons">
                  <img
                    src={instagramLogo}
                    alt="Instagram"
                    className="iconProperties"
                  />
                </div>
              </a>
              <a
                href="https://www.tiktok.com/@devclimbersmx?lang=es"
                className="footer2-link"
              >
                <div className="footer2Icons">
                  <img
                    src={tiktokLogo}
                    alt="TikTok"
                    className="iconProperties"
                  />
                </div>
              </a>
              <a
                href="https://www.linkedin.com/company/devclimbers/about/"
                className="footer2-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="footer2Icons">
                  <img
                    src={linkedinLogo}
                    alt="LinkedIn"
                    className="iconProperties"
                  />
                </div>
              </a>
              <a
                href="https://www.facebook.com/profile.php?id=61558054921916"
                className="footer2-link"
              >
                <div className="footer2Icons">
                  <img src={xLogo} alt="X" className="iconProperties" />
                </div>
              </a>
            </div>
          </div>
        </div>
        {/* <hr className="footerhr" /> */}
        <img
          src={decorR}
          alt="decoracion Izquierda"
          className="footer-imagesR"
        />
      </div>
      <div className="footer2Row">
        <img src={devLogo2} alt="Dev Logo" className="footer2logoImage" />
      </div>
      <div className="footer2copy">
        © 2024 DevClimbers. All rights reserved.
      </div>

      <div className="footer2Row">
        <div className="">
          <a href="/legal" className="footer2-link">
            Legal Information
          </a>
        </div>
        <div className=""> | </div>
        <div className="">
          <a href="/privacy-policy" className="footer2-link">
            Privacy Policy
          </a>
        </div>
      </div>
    </div>
  );

  //     return (
  //         <div className="footerBox">
  //             <h1 className="footerh1">
  //                 {'<DevClimbers/>'}: To the top!
  //             </h1>
  //             <div className="footerContainer">
  //                 <div className="footerRow">
  //                     <div className="footerColumn">
  //                         <p className="footerHeading">About Us</p>
  //                         <a className="footerLink" href="">Quiénes Somos</a>
  //                         <a className="footerLink" href="">Misión</a>
  //                         <a className="footerLink" href="">Visión</a>
  //                         <a className="footerLink" href="">Testimonios</a>
  //                     </div>
  //                     <div className="footerColumn">
  //                         <p className="footerHeading">Services</p>
  //                         <a className="footerLink" href="">Desarrollo Web</a>
  //                         <a className="footerLink" href="">Desarrollo Móvil</a>
  //                         <a className="footerLink" href="">Consultoría</a>
  //                         <a className="footerLink" href="">Soporte Técnico</a>
  //                     </div>
  //                     <div className="footerColumn">
  //                         <p className="footerHeading">Contact Us</p>
  //                         <a className="footerLink" href="">123 Calle Principal</a>
  //                         <a className="footerLink" href="">Ciudad, País</a>
  //                         <a className="footerLink" href="">+123 456 7890</a>
  //                         <a className="footerLink" href="">contacto@devclimbers.com</a>
  //                     </div>
  //                     <div className="footerColumn">
  //                         <p className="footerHeading">Social Media</p>
  //                         <a className="footerLink" href="#">
  //                             <i className="fab fa-facebook-f">
  //                                 <span style={{ marginLeft: "10px" }}>
  //                                     Facebook
  //                                 </span>
  //                             </i>
  //                         </a>
  //                         <a className="footerLink" href="">
  //                             <i className="fab fa-instagram">
  //                                 <span style={{ marginLeft: "10px" }}>
  //                                     Instagram
  //                                 </span>
  //                             </i>
  //                         </a>
  //                         <a className="footerLink" href="">
  //                             <i className="fab fa-twitter">
  //                                 <span style={{ marginLeft: "10px" }}>
  //                                     Twitter
  //                                 </span>
  //                             </i>
  //                         </a>
  //                         <a className="footerLink" href="">
  //                             <i className="fab fa-linkedin">
  //                                 <span style={{ marginLeft: "10px" }}>
  //                                     LinkedIn
  //                                 </span>
  //                             </i>
  //                         </a>
  //                     </div>
  //                 </div>
  //             </div>
  //         </div>
  //     );
};

export default Footer;
