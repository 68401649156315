import React from 'react'
import { useLocation } from 'react-router-dom';

const ProjectDetails = () => {
	console.log(useLocation());
	const id = 8
	//const id = props.match.params.id
  return (
	<div className='container section project-details'>
		<div className='card z-depth-0'>
			<div className='card-content'>
				<span className='card-title'>Project title - {id}</span>
				<p>lorem ipsum dolor</p>
			</div>
			<div className='card-action great lighten-4 grey-text'></div>
			<div> Posted by Daniel Aquino</div>
			<div>2nd September, 2am</div>
		</div>

	</div>
  )
}

export default ProjectDetails