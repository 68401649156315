import { useLocation  } from 'react-router-dom';
import { useState, useEffect } from 'react';

function SignedOutLinks() {
  const [currentHash, setCurrentHash] = useState('');
  const location = useLocation();

  useEffect(() => {
    setCurrentHash(location.hash);
  }, [location.hash]);



  return (
    <ul className="navbar-nav ml-auto">
      {/* <li className="nav-item">
    <NavLink 
        exact 
        to="/#home" 
        className={`nav-link ${currentHash === "#home"  || currentHash === "" ? "active" : ""}`}
        >
        {currentHash === "/#home" ? "[HOME]" : "HOME"}
    </NavLink>
</li>
<li className="nav-item">
    <NavLink 
        to="/#projects" 
>
        {currentHash === "/#projects" ? "[PROJECTS]" : "PROJECTS"}
    </NavLink>
</li>
<li className="nav-item">
    <NavLink 
        to="/#aboutus" 
        className="nav-link" 
        activeClassName="active">
        {currentHash=== "/#aboutus" ? "[ABOUT US]" : "ABOUT US"}
    </NavLink>
</li>
<li className="nav-item">
    <NavLink 
        to="/#contactus" 
        className="nav-link" 
        activeClassName="active">
        {currentHash=== "/#contactus" ? "[CONTACT US]" : "CONTACT US"}
    </NavLink>
</li> */}

      <li className="nav-item">
        <a
          className={`nav-link ${
            currentHash === "#home" || currentHash === "" ? "active" : ""
          }`}
          href="/#home"
        >
          HOME
        </a>
      </li>
      {/* <li className="nav-item">
        <a className={`nav-link ${currentHash === "#projects" ? "active" : ""}`} href='/#projects'>
          {currentHash === "#projects" ? "[PROJECTS]" : "PROJECTS"}
        </a>
      </li> */}
      <li className="nav-item">
        <a
          className={`nav-link ${currentHash === "#aboutus" ? "active" : ""}`}
          href="/#aboutus"
        >
          ABOUT US
        </a>
      </li>
      <li className="nav-item">
        <a
          className={`nav-link ${currentHash === "#services" ? "active" : ""}`}
          href="/#services"
        >
          SERVICES
        </a>
      </li>
      <li className="nav-item">
        <a
          className={`nav-link ${currentHash === "#contactus" ? "active" : ""}`}
          href="/#contactus"
        >
          CONTACT
        </a>
      </li>

      {/* <li className="nav-item">
                <NavLink className="nav-link" to='/signin'>LOGIN</NavLink>
            </li>
            <li className="nav-item">
                <NavLink className="nav-link" to='/signup'>SIGNUP</NavLink>
            </li> */}
    </ul>
  );
}

export default SignedOutLinks;
