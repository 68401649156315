import { useState } from "react";
import Alert from "react-bootstrap/Alert";
import { PiGearSixBold } from "react-icons/pi";
import '../alerts/alert.css'

function AlertDismisable() {
  const [show, setShow] = useState(true);

  if (show) {
    return (
      <Alert
        className="custom-alert"
        variant="info"
        onClose={() => setShow(false)}
        dismissible
      >
        <PiGearSixBold className="custom-alert-icon" />
        <p className="custom-alert-text">
          Our website is currently under construction and is constantly being
          improved on
        </p>
      </Alert>
    );
  }
  return null;
}

export default AlertDismisable;
