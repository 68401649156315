import React from 'react';
import '../../css/404Page.css';
import '../../css/glitch.css';

const NotFoundPage = () => {
    return (
        <div className="not-found-container">
            {/* <h1 className='not-found-title'>404</h1> */}
			<h1 class="hero glitch layers not-found-title" data-text='404'>
          <span>404</span>
        </h1>
            <p className='not-found-text'>¡Ups! Parece que te has perdido en el código.</p>
            {/* <div className="image-container">
                <div className="web-icon"></div>
                <div className="mobile-icon"></div>
                <div className="software-icon"></div>
            </div> */}
            <p className='not-found-text'>Regresa al <a className='not-found-link' href="/">inicio</a> o verifica la URL.</p>
        </div>
    );
}

export default NotFoundPage;
